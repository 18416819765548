import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/http/api.service';
import { DropdownItem } from '../models/common.model';
import { API_URL } from './api.constant';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(
    private http: HttpClient,
    private httpClient: ApiService,
  ) {}

  getDropdown<T = number>(
    params,
  ): Observable<{ [dropdownName: string]: DropdownItem<T>[] }> {
    return this.httpClient.get<{
      [dropdownName: string]: DropdownItem<T>[];
    }>(API_URL.dropdown, params);
  }

  getDropdownWithHeader(header, params) {
    return this.http.get(environment.baseUrl + API_URL.dropdown, {
      params,
      headers: header,
    });
  }

  memoTypeItem(ar_memo: boolean) {
    if (ar_memo) {
      return [
        {
          label: 'Quotation',
          value: 'quotation',
          context: {
            display_name: 'ใบเสนอราคา',
            display_name_en: 'Quotation',
          },
        },
      ];
    } else {
      return [
        {
          label: 'External',
          value: 'external',
          context: {
            display_name: 'แบบขออนุมัติภายนอก',
            display_name_en: 'External',
          },
        },
        {
          label: 'Internal',
          value: 'internal',
          context: {
            display_name: 'แบบขออนุมัติภายใน',
            display_name_en: 'Internal',
          },
        },
        {
          label: 'Purchase Request',
          value: 'purchase_request',
          context: {
            display_name: 'แบบขออนุมัติการซื้อ',
            display_name_en: 'Purchase Request',
          },
        },
        {
          label: 'Payment Voucher',
          value: 'payment_voucher',
          context: {
            display_name: 'บันทึกรายจ่าย',
            display_name_en: 'Payment Voucher',
          },
        },
        {
          label: 'Upload',
          value: 'upload',
          context: {
            display_name: 'เอกสารอัปโหลด',
            display_name_en: 'Upload',
          },
        },
        {
          label: 'VM Request',
          value: 'vm_request',
          context: {
            display_name: 'ใบคำขอ VM',
            display_name_en: 'VM Request',
          },
        },
        {
          label: 'Contract',
          value: 'contract',
          context: {
            display_name: 'สัญญา',
            display_name_en: 'Contract',
          },
        },
      ];
    }
  }
}

export interface DropdownResponse {
  [type: string]: {
    label: any;
    value: any;
    context?: any;
  }[];
}
